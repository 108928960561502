import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PropTypes from 'prop-types';
import { getIsPreviewStatus } from '../../state/app';
import { connect } from 'react-redux';
// import Select from 'react-select';
import ReCAPTCHA from 'react-google-recaptcha';
import { RichText } from '../richTextOptions';
import Axios from 'axios';

const IconUpload = () => (
     <svg xmlns="http://www.w3.org/2000/svg" width="17.938" height="19.219" viewBox="0 0 17.938 19.219">
          <path
               id="Path_1568"
               data-name="Path 1568"
               d="M22.938,15.25l-8.969,8.969L5,15.25H6.807l6.522,6.522V5h1.281V21.772l6.522-6.522Z"
               transform="translate(22.938 24.219) rotate(180)"
               fill="#0047bb"
          />
     </svg>
);

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema = (setValidateOnChange) => {
     setValidateOnChange(true);
     return Yup.object().shape({
          policyNumber: Yup.string().required('This field is required.'),
          yourFullName: Yup.string().required('This field is required.'),
          yourPetName: Yup.string().required('This field is required.'),
          yourEmail: Yup.string()
               .required('This field is required.')
               .matches(REGEX_EMAIL, 'Not a valid email'),
          stateOfResidence: Yup.string().required('This field is required.'),
          tellUsYourStory: Yup.string().required('This field is required.'),
          receiveReimbursement: Yup.string().required('This field is required.'),
          captchaToken: Yup.string().required('CAPTCHA failed. Please try again!'),
          uploadPhoto: Yup.string().required('File Upload field is required.'),

          // uploadPhoto: Yup.mixed().required('Image is required').test(
          //      'fileFormat',
          //      'Unsupported file format',
          //      value => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
          //    )

     });
};

const ShowError = ({ errorMessage }) => {
     if (!errorMessage) return <></>;
     return <span className="tell-your-story-form-error">{errorMessage}</span>;
};

const SubmitSuccess = () => {
     return (
          <div className="tell-your-story-submit-success">
               <h2>Thank You</h2>
               <p>Thanks for sharing your story!</p>
          </div>
     );
};

const SectionTellYourStory = (props) => {
     const receiveReimbursementOptions = [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
     ];
     const { policyNumber, yourFullName, yourPetName, yourEmail, stateOfResidence, tellUsYourStory, receiveReimbursement, uploadPhotoText } = props;
     const initialValues = {
          policyNumber: '',
          yourFullName: '',
          yourPetName: '',
          yourEmail: '',
          stateOfResidence: '',
          tellUsYourStory: '',
          receiveReimbursement: '',
          uploadPhoto: '',
          captchaToken: ''
     };

     const recaptchaRef = useRef();
     const [validateOnChange, setValidateOnChange] = useState(false);
     const [selectedFile, setSelectedFile] = useState();
     const [preview, setPreview] = useState();
     const [isSubmitted, setIsSubmitted] = useState(false);
     const photoInput = useRef();

     useEffect(() => {
          if (!selectedFile) {
               setPreview(undefined);
               return;
          }

          const objectUrl = URL.createObjectURL(selectedFile);
          setPreview(objectUrl);

          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl);
     }, [selectedFile]);

     const onSelectFile = (e, setFieldValue) => {
          if (!e.target.files || e.target.files.length === 0) {
               setSelectedFile(undefined);
               return;
          }
          // delete errors.uploadPhoto
          // setErrors(errors)

          // I've kept this example simple by using the first image instead of multiple
          setSelectedFile(e.target.files[0]);
          setFieldValue('uploadPhoto',e.target.files[0] )

     };
     const handleFormSubmit = (values) => {
          const token = recaptchaRef.current.getValue();
          recaptchaRef.current.reset();

          let newValues = {
               policyNumber: values.policyNumber,
               yourFullName: values.yourFullName,
               yourPetName: values.yourPetName,
               yourEmail: values.yourEmail,
               stateOfResidence: values.stateOfResidence,
               tellUsYourStory: values.tellUsYourStory,
               receiveReimbursement: values.receiveReimbursement
          };

          const data = new FormData();
          data.append('uploadPhoto', selectedFile);

          for (let key in newValues) {
               if (newValues[key]) {
                    data.append(key, newValues[key]);
               }
          }

          if (token) {
               setIsSubmitted(true);

               Axios({
                    method: 'post',
                    url: `https://marketing.nationwide.com/WQGBu5kpXKW4KeQ5`,
                    data
               }).then(
                    (response) => {
                         console.log(response);
                    },
                    (reject) => {
                         console.log(reject);
                    }
               );
          }
     };

     const handleRemoveImagePreview = () => {
          photoInput.current.value = '';
          setSelectedFile(undefined);
     };

     const handleKeyDown = (e) => {
          if (e.key === 'Enter') {
               photoInput.current.click();
          }
     };

     return (
          <>
               <section className="sub-title-story">
                    <div className="container">
                         <RichText data={props.subTitleStory}></RichText>
                    </div>
               </section>
               <section className="tell-your-story">
                    <div className="container">
                         {isSubmitted ? (
                              <SubmitSuccess></SubmitSuccess>
                         ) : (
                              <>
                                   <Formik
                                        initialValues={initialValues}
                                        validationSchema={() => validationSchema(setValidateOnChange)}
                                        onSubmit={handleFormSubmit}
                                        validateOnChange={validateOnChange}
                                        validateOnBlur={false}
                                   >
                                        {({ values, errors,setErrors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {

                                             return (
                                                  <form onSubmit={handleSubmit} className="tell-your-story-form">
                                                       <div className="position-relative">
                                                            <input
                                                                 className={`${errors.policyNumber ? 'field-is-error' : ''}`}
                                                                 placeholder={policyNumber}
                                                                 type="text"
                                                                 name="policyNumber"
                                                                 onChange={handleChange}
                                                                 value={values.policyNumber}
                                                            />
                                                            <ShowError errorMessage={errors.policyNumber} />
                                                       </div>
                                                       <div className="row">
                                                            <div className="col-md-6">
                                                                 <div className="position-relative">
                                                                      <input
                                                                           className={`${errors.yourFullName ? 'field-is-error' : ''}`}
                                                                           placeholder={yourFullName}
                                                                           type="text"
                                                                           name="yourFullName"
                                                                           onChange={handleChange}
                                                                           value={values.yourFullName}
                                                                      />
                                                                      <ShowError errorMessage={errors.yourFullName} />
                                                                 </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                 <input
                                                                      className={`${errors.yourPetName ? 'field-is-error' : ''}`}
                                                                      placeholder={yourPetName}
                                                                      type="text"
                                                                      name="yourPetName"
                                                                      onChange={handleChange}
                                                                      value={values.yourPetName}
                                                                 />
                                                                 <ShowError errorMessage={errors.yourPetName} />
                                                            </div>
                                                       </div>
                                                       <div className="position-relative">
                                                            <input
                                                                 className={`${errors.yourEmail ? 'field-is-error' : ''}`}
                                                                 placeholder={yourEmail}
                                                                 type="text"
                                                                 name="yourEmail"
                                                                 onChange={handleChange}
                                                                 value={values.yourEmail}
                                                            />
                                                            <ShowError errorMessage={errors.yourEmail} />
                                                       </div>
                                                       <div className="position-relative">
                                                            <input
                                                                 className={`${errors.stateOfResidence ? 'field-is-error' : ''}`}
                                                                 placeholder={stateOfResidence}
                                                                 type="text"
                                                                 name="stateOfResidence"
                                                                 onChange={handleChange}
                                                                 value={values.stateOfResidence}
                                                            />
                                                            <ShowError errorMessage={errors.stateOfResidence} />
                                                       </div>
                                                       <div className="position-relative">
                                                            <textarea
                                                                 name="tellUsYourStory"
                                                                 id=""
                                                                 cols="30"
                                                                 rows="10"
                                                                 placeholder={tellUsYourStory}
                                                                 value={values.tellUsYourStory}
                                                                 onChange={handleChange}
                                                                 className={`${errors.tellUsYourStory ? 'field-is-error' : ''}`}
                                                            ></textarea>
                                                            <ShowError errorMessage={errors.tellUsYourStory} />
                                                       </div>
                                                       <p>{receiveReimbursement}</p>
                                                       {/* <Select
                                                            tabIndex={0}
                                                            aria-label="Select"
                                                            options={receiveReimbursementOptions}
                                                            placeholder="Select"
                                                            className="tell-your-story-form-select"
                                                            classNamePrefix="tell-your-story-form-select"
                                                            isSearchable={false}
                                                            onChange={(e) => {
                                                                 setFieldValue('receiveReimbursement', e.value);
                                                            }}
                                                       /> */}

                                                       <div className="position-relative">
                                                            <select
                                                                 aria-label="Select"
                                                                 placeholder="Select"
                                                                 className="tell-your-story-form-select"
                                                                 // onChange={handleChange}
                                                                 onChange={(e) => {
                                                                      setFieldValue('receiveReimbursement', e.target.value);
                                                                 }}
                                                            >
                                                                 <option value="">Select</option>
                                                                 {receiveReimbursementOptions.map((value) => {
                                                                      return <option value={value.label}>{value.value}</option>;
                                                                 })}
                                                            </select>
                                                            <ShowError errorMessage={errors.receiveReimbursement} />
                                                       </div>

                                                       <div className="tell-your-story-form-upload position-relative">
                                                            <div className={`${errors.uploadPhoto ? 'field-is-error' : ''} tell-your-story-form-upload-button`}>
                                                                 <input ref={photoInput} type="file" id="upload" hidden accept="image/*" onChange={(e)=>onSelectFile(e, setFieldValue)} />

                                                                 <label tabIndex={0} htmlFor="upload" onKeyDown={handleKeyDown}>
                                                                      <IconUpload />
                                                                      {uploadPhotoText}
                                                                 </label>

                                                            </div>
                                                            <ShowError errorMessage={errors.uploadPhoto} />

                                                            {selectedFile && (
                                                                 <div className="tell-your-story-form-upload-preview">
                                                                      <button onClick={handleRemoveImagePreview}>X</button>
                                                                      <img loading="lazy" src={preview} alt="Preview Image" />
                                                                 </div>
                                                            )}
                                                       </div>

                                                       <div className="position-relative">
                                                            <ReCAPTCHA
                                                                 onChange={(e) => {
                                                                      setFieldValue('captchaToken', e);
                                                                 }}
                                                                 className={`tell-your-story-form-recaptcha${errors.captchaToken ? ' field-is-error' : ''}`}
                                                                 sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                                 ref={recaptchaRef}
                                                                 size="normal"
                                                            />
                                                            <ShowError errorMessage={errors.captchaToken} />
                                                       </div>
                                                       <button className="tell-your-story-form-submit" type="submit">Submit now</button>
                                                  </form>
                                             );
                                        }}
                                   </Formik>
                              </>
                         )}
                    </div>
               </section>
          </>
     );
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

SectionTellYourStory.propTypes = {
     isPreview: PropTypes.bool
};

const ContentfulSectionTellYourStory = connect(mapStateToProps)(SectionTellYourStory);

export default ContentfulSectionTellYourStory;
